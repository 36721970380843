<template>
  <div class="timeWidget elevation-4 mr-3 px-3">
    <v-icon class="icon" v-text="'$time'" />
    <span class="ml-2 time" :class="{ hidden: loading }">
      <span>{{ `${hours}:${minutes}` }}</span>
    </span>
  </div>
</template>

<script>
import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref
} from '@vue/composition-api';
import { getTimezoneShift } from '@/modules/about/getVersion';
import { getDateWithTimeZone } from '@/utils';

export default {
  name: 'TimeWidget',
  setup() {
    let timer = null;
    const date = ref(getDateWithTimeZone(0));
    const loading = ref(true);

    const setDate = timezone => {
      date.value = getDateWithTimeZone(timezone);
    };

    onMounted(async () => {
      let timezone = 0;

      try {
        timezone = await getTimezoneShift();
      } finally {
        loading.value = false;
      }

      setDate(timezone);

      timer = setInterval(() => {
        setDate(timezone);
      }, 1000);
    });
    onBeforeUnmount(() => {
      timer && clearInterval(timer);
    });

    return {
      date,
      loading,
      hours: computed(() => date.value.getUTCHours() < 10 ? `0${date.value.getUTCHours()}`: date.value.getUTCHours()),
      minutes: computed(() => date.value.getUTCMinutes() < 10 ? `0${date.value.getUTCMinutes()}`: date.value.getUTCMinutes())
    };
  }
};
</script>

<style lang="scss">
.timeWidget {
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 1000px;
  background-color: #ffffff;
}
.icon {
  color: inherit !important;
}
.time {
  line-height: initial !important;
  font-size: 16px !important;
  font-weight: 600;
}

.hidden {
  visibility: hidden;
}
</style>
