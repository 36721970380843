<template>
  <div class="map-wrapper">
    <div id="map" class="map" :class="{hidden: !isAppDataLoaded}" />
    <headerMenu />
    <footerMenu />
    <track-animation-control />
    <object-popup
      v-model="showMarkerPopup"
      :object-id="objectId"
      :position-x="markerPopupCoordinates[0]"
      :position-y="markerPopupCoordinates[1]"
    />
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api';

import headerMenu from './headerMenu';
import footerMenu from './footerMenu';
import TrackAnimationControl from './trackAnimationControl';
import ObjectPopup from '@/modules/objects/ui/ObjectPopup';

import { useMap } from '@/compositions/map';
import {useAuth} from "@/modules/auth/api";

export default {
  name: 'MapComponent',
  components: {
    headerMenu,
    footerMenu,
    ObjectPopup,
    TrackAnimationControl
    //pop
  },
  setup(props, { root }) {
    const {
      mount,
      markers: { onClickMarker, onHoverMarker }
    } = useMap();
    const { isAppDataLoaded } = useAuth();
    const showMarkerPopup = ref(false);
    const markerPopupCoordinates = ref([0, 0]);
    const objectId = ref('');

    onMounted(() => {
      mount(document.getElementById('map'));
      onClickMarker(id => {
        root.$router.push({
          name: 'object_card',
          params: { ...root.$route.params, objectId: id }
        });
      });
      onHoverMarker(param => {
        const { hover, coordinates, id } = param;
        if (hover) {
          const [x, y] = coordinates;
          markerPopupCoordinates.value = [x, y];
          objectId.value = id;
        }
        showMarkerPopup.value = hover;
      });
    });

    return {
      markerPopupCoordinates,
      showMarkerPopup,
      objectId,
      isAppDataLoaded
    };
  }
};
</script>

<style lang="sass" scoped>
.map-wrapper
  width: 100%
  height: 100vh
  .map
    width: 100%
    height: 100%
.hidden
  visibility: hidden
</style>
